import './bootstrap';
import '../css/app.scss';

import Layout from './Layouts/Layout.vue'
import { createApp, h } from 'vue'
import { createInertiaApp, Head, Link } from '@inertiajs/vue3'
import { reactive } from 'vue';
import helpers from "./Helpers"
import axios from 'axios';
import * as Sentry from "@sentry/vue";

// Axios
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

createInertiaApp({
    title: (title) => `${title} - Autobesikos`,
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
        let page = pages[`./Pages/${name}.vue`]
        page.default.layout = page.default.layout || Layout
        return page
    },
    setup({el, App, props, plugin}) {

        const myApp = createApp({ render: () => h(App, props) })
        .use(plugin)
        .component('Head', Head)
        .component('Link', Link)

        Sentry.init({
            app: myApp,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            environment: import.meta.env.APP_ENV,
        });

        const global = reactive({
            cookies: window.localStorage.getItem('cookies_autobesikos') ? true : false,
            payment_method: false,
            current_url: '',
            current_pathname: '',
            no_image: '/images/no_image_v2.png',
            message: { open: false, text: null, color: null },
            popup: { open: false, id: null, slide: null },
            origin: window.location.origin,
            isMobile: Boolean,
            isTablet: Boolean,
            orientation: '',
            filtersLoading: false,
            favorites_array: window.localStorage.getItem('favorites_cars') ? JSON.parse(window.localStorage.getItem('favorites_cars')).map((item) => item.id) : [],
        })

        myApp.config.globalProperties.$global = global;
        myApp.config.globalProperties.$helper = helpers;

        myApp.mount(el);
        return myApp;
    },
})
