<template>
    <div class="submenu">
        <div class="submenu-text" @click="activeMenu = !activeMenu">
            <p v-if="text">{{ text }}</p>
            <slot v-else></slot>
            <Arrow class="arrow" :class="activeMenu ? 'arrow-rotate-up' : 'arrow-rotate-down'" />
        </div>
        <div v-if="activeMenu" class="submenu-item">
            <div v-for="item in submenu" :key="item.href" @click="activeMenu = !activeMenu">
                <Link v-if="!item.external" :method="item.href === '/logout' ? 'post' : 'get'" as="button" :href="item.href">{{ item.name }}</Link>
                <a v-else :href="item.href" target="_blank">{{ item.name }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import { Arrow } from "../../Icons/Icons";
    import { ref } from "vue";

    export default {
        components: {
            Arrow
        },
        props: {
            text: String,
            submenu: Object,
        },
        setup() {
            const activeMenu = ref(false);

            return { activeMenu }
        },
        methods: {
            closeMenu(e) {
                if (!this.$el.contains(e.target)) {
                    this.activeMenu = false;
                }
            }
        },
        mounted() {
            document.addEventListener('click', this.closeMenu)
        },
        beforeUnmount() {
            document.removeEventListener('click',this.closeMenu)
        }
    }
</script>