<template>
   <div :class="className ? `${className}-2` : 'car-payment-slider-2'">
        <h3>Προκαταβολή</h3>
        <div class="form-payment">
            <input type="number" id="payment" v-model="values.prokatavoli" :placeholder="values.prokatavoli"/>
            <div>€</div>
        </div>
        <p>Εισάγετε το ποσό που μπορείτε να καταβάλλετε μετρητοίς κατά την αγορά του αυτοκινήτου σας</p>
    </div>
    <div :class="className ? `${className}-3` : 'car-payment-slider-3'">
        <h3>Διάρκεια χρηματοδότησης</h3>
        <Range @slider_value="getDoseis" :car_id="car" :value="values.doseis" :min="2" :max="72" :step="1" />
        <p>Επιλέξτε τη χρονική διάρκεια της χρηματοδότησης σε μήνες</p>
    </div>
</template>

<script>
    import Range from "../Slider/Range.vue";
    import { reactive } from "vue";

    export default {
        components: {
            Range
        },
        props: {
            car: Number,
            price: Number,
            salePrice: Number,
            className: String
        },
        setup(props, { slots, attrs }) {
            const values = reactive({
                doseis: 48,
                prokatavoli: 3000,
            });

            return { values };
        },
        data() {
            return {
                firstLoad: true,
            }
        },
        emits: ['dosi_value', 'prokatavoli_value'],
        watch: {
            values: { handler(data) {
                if ( !this.firstLoad && this.car ) {
                    const timer = 600;
                    // Debounce effect
                    clearTimeout(this.debounceFilters);
                    this.debounceFilters = setTimeout(() => { 
                        this.getMonthlyData(data.prokatavoli, data.doseis) 
                    }, timer)
                } 
                if ( !this.car ) {
                    this.emitFunction();
                }
            }, deep: true },
        },
        methods: {
            getDoseis(value) {
                this.values.doseis = value;
            },
            getMonthlyData(prokatavoli, doesis) {
                axios.post(`${window.location.origin}/api/installments/${this.car}`, {
                    prokatavoli: prokatavoli,
                    mines: doesis
                }).then((response) => {
                    this.$emit('dosi_value', response.data.dosi);
                })
            },
            emitFunction() {
                this.$emit('dosi_value', this.values.doseis);
                this.$emit('prokatavoli_value', this.values.prokatavoli);
            }
        },
        created() {
            if ( this.car ) {
                axios.post(`${window.location.origin}/api/installments/${this.car}`).then((response) => {
                    if ( this.firstLoad ) {
                        this.values.prokatavoli = response.data.prokatavoli;
                        this.values.doseis = response.data.mines;
                        this.firstLoad = false;
                    }
                })
            }
        }
    }
</script> 