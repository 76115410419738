const helpers = {
    getFormMessage: (success, message, state, text, color, data, event) => {
        // Display 
        message.open = state;
        message.text = text;
        message.color = color;
        // Push Data (GA)
        if ( success && process.env.NODE_ENV !== 'development' ) { dataLayer.push({ 'event': event.replace('/', '') }); }
        // Fail
        if ( !success ) { console.log(data); }
    },
    getMessage: (message, state, text, color) => {
        message.open = state;
        message.text = text;
        message.color = color;
    },
    firstLetterUppercase: (title) => {
        return title.charAt(0).toUpperCase()+title.slice(1);
    },
    replaceCharacter: (title, character, replace) => {
        const theTitle = title.toString();
        const regExp = new RegExp(character, "g");
        const replaceTitle = theTitle.replace(regExp, replace);

        return replaceTitle
    },
    capitalizeReplace: (title) => {
        const theTitle = title.toString();
        const replaceTitle = theTitle.replace(/-/g, ' ');
        const capitalizeTitle = replaceTitle.charAt(0).toUpperCase()+replaceTitle.slice(1);

        return capitalizeTitle;
    },
    capitilzeEachWord: (title, character) => {
        const theTitle = title.toString();
        const splitTitle = theTitle.split(character);
        const arrayTitle = splitTitle.map((item) => {
            return item.charAt(0).toUpperCase()+item.slice(1);
        })
        const arrayTitleString = arrayTitle.toString();
        const replaceTitle = arrayTitleString.replace(/,/g, ' ');

        return replaceTitle;
    },
    upperCaseReplace: (title) => {
        const theTitle = title.toString();
        const replaceTitle = theTitle.replace(/-/g, ' ');
        const uppercaseTitle = replaceTitle.toUpperCase();

        return uppercaseTitle;
    },
    toLocaleReplace: (title) => {
        return title.toLocaleString('el').replace(/,/g, '.');
    },
    convertTimeToMonth: (time) => {
        let newTime = new Date(time);
        let convertedTime = `${newTime.getDate()} ${newTime.toLocaleString('el-GR', { month: 'long' })}  ${newTime.getFullYear()}`
        return convertedTime
    },
    popupTrigger: (id) => {
        window.localStorage.setItem('popup_autobesikos', id);
    },
    padNumber(number, length, character) {
        let pad_char = typeof character !== 'undefined' ? character : '0';
        let pad = new Array(1 + length).join(pad_char);
        return (pad + number).slice(-pad.length);
    }
}

export default helpers