<template>
    <Popup @popup_open="popupOpen">
        <div class="car-images-text mobile-only">
            <Rotate />
            <h2>Περίστρεψε για μεγαλύτερες εικόνες!</h2>
        </div>
        <div v-if="carsArray.length > 0" class="car-images-buttons no-select">
            <button v-for="(text, index) in activeImages.text" :class="activeTab === index+1 ? `car-images-button-active car-images-button-active-${index+1}` : ''" class="car-images-button" @click="setActiveTab(index+1)">{{ text }}</button>
            <div class="car-images-button-holder"></div>
        </div>
        <div class="car-images" v-show="$global.popup.id === 1">
            <swiper v-if="$global.orientation === ''" @swiper="onSwiper" @slideChange="onSlideChange" :lazy="true" :centeredSlides="true" :spaceBetween="0" :navigation="true" :effect="'coverflow'" :coverflowEffect="coverFlow" :grabCursor="true" :slidesPerView="'1.5'" :pagination="{type: 'fraction' }" :modules="modules" class="car-box-slider">
                <swiper-slide v-for="(image, index) in carsArray" :key="index">
                    <img class="car-images-img" :src="image" :alt="car+' - View model'" loading="lazy">
                </swiper-slide>
            </swiper>
            <swiper v-else @swiper="onSwiper" @slideChange="onSlideChange" :lazy="true" :centeredSlides="true" :spaceBetween="20" :navigation="true" :grabCursor="true" :slidesPerView="'1.2'" :pagination="{type: 'fraction' }" :modules="modules" class="car-box-slider">
                <swiper-slide v-for="(image, index) in carsArray" :key="index">
                    <img class="car-images-img" :src="image" :alt="car+' - View model'" loading="lazy">
                </swiper-slide>
            </swiper>
        </div>
    </Popup>
</template>

<script>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { EffectCoverflow, Pagination, Navigation } from 'swiper';
    import { Rotate } from '../../Icons/Icons';
    import Popup from '../Popup/Popup.vue';
    import "swiper/css/navigation";
    import 'swiper/css/effect-coverflow';
    import "swiper/css/pagination";

    export default {
        components: {
            Swiper, SwiperSlide, Popup,
            Rotate
        },
        props: {
            images: Object,
            car: String,
            slidesPerView: Number,
            currentSlide: Number,
        },
        setup() {
            const coverFlow = {
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slide: 0,
                slideShadows: true,
            }
            
            return { coverFlow, modules: [EffectCoverflow, Pagination, Navigation] };
        },
        data() {
            return {
                swiper: null,
                open: this.$global.popup.id === 1 ? true : false,
                activeTab: 1,
                activeImages: {text: [], number: []},
                carsArray: [],
                carsObject: { exterior: Number, interior: Number, other: Number },
            }
        },
        watch: {
            currentSlide: { handler(value) { this.swiper.slideTo(value) }, deep: true },
        },
        methods: {
            setImageArray(array, text ) {
                let getArray = [];

                if (this.images[array].length > 0) {
                    getArray.push(...this.images[array]);
                    this.carsArray.push(...this.images[array]);
                } else {
                    getArray.push(this.$global.no_image,this.$global.no_image,this.$global.no_image,this.$global.no_image);
                    this.carsArray.push(this.$global.no_image,this.$global.no_image,this.$global.no_image,this.$global.no_image);
                }

                this.carsObject[array] = getArray.length;
                this.activeImages.text.push(text);
            },
            carImages() {
                if ( this.images.exterior ) {
                    this.setImageArray('exterior', 'Εξωτερικό');
                    this.activeImages.number.push(this.carsObject.exterior);
                }

                if ( this.images.interior ) {
                    this.setImageArray('interior', 'Εσωτερικό');
                    this.activeImages.number.push(this.carsObject.exterior + this.carsObject.interior);
                }
                if ( this.images.other ) {
                    this.setImageArray('other', 'Άλλες');
                    this.activeImages.number.push(this.carsObject.exterior + this.carsObject.interior + this.carsObject.other);
                }
      
                return this.carsArray
            },
            setActiveTab(value) {
                this.activeTab = value;

                if ( value === 1) { this.swiper.slideTo(0); }
                if ( value === 2) { this.swiper.slideTo(this.activeImages.number[0]); }
                if ( value === 3) { this.swiper.slideTo(this.activeImages.number[1]); }
            },
            onSwiper(swiper) {
                this.swiper = swiper;
            },
            onSlideChange(swiper) {
                this.slide = swiper.realIndex;

                if (this.activeImages.number[0]) {
                    if (swiper.realIndex < this.activeImages.number[0]) { this.activeTab = 1; }
                    if (this.activeImages.number[1]) {
                        if (swiper.realIndex >= this.activeImages.number[0] && swiper.realIndex < this.activeImages.number[1]) { this.activeTab = 2; }
                        if (this.activeImages.number[2]) {
                            if (swiper.realIndex >= this.activeImages.number[1] && swiper.realIndex < this.activeImages.number[2]) { this.activeTab = 3; }
                        }
                    }
                }
            },
            popupOpen(value) {

            },
        },
        mounted() {
            this.carImages();
        },
        unmounted() {
        }
    }
</script>