<template>
    <section class="popup-main" v-show="$global.popup.open">
        <div class="popup-close">
            <Close width="35" height="35" color="#fff" @click="triggerPopup(false, null)" />
        </div>
        <div class="popup-content">
            <slot />
        </div>
    </section>
</template>

<script>
    import { Close } from '../../Icons/Icons';

    export default {
        components: {
            Close
        },
        props: {

        },
        data() {
            return {
                open: false,
            }
        },
        methods: {
            triggerPopup(value, id) {
                this.$global.popup.open = value;
                this.$global.popup.id = id;
            },
            emitOpen(value) {
                this.$emit('popup_open', value);
            },
            updatedTrigger() {
                if ( this.$global.popup.open && !this.open ) {
                    this.open = true;
                    this.emitOpen(true);
                    document.body.classList.add("hide-scroll");
                } else if ( !this.$global.popup.open && this.open) {
                    this.open = false;
                    document.body.classList.remove("hide-scroll");
                    this.emitOpen(false);
                }
            }
        },
        updated() {
            this.updatedTrigger();
        },
    }
</script>